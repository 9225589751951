import useWindowDimensions from "../components/windowHelper";
import { useEffect, useRef, useState } from "react";
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from "@mui/icons-material";

const Music = (props) => {
    const { width } = useWindowDimensions();

    const audioRef = useRef();
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const numTracks = 4;

    const tracks = [
        '/alice_in_wonderland.mp3',
        '/emily.mp3',
        '/drive.mp3',
        '/lovely.mp3',
    ];

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying, audioRef]);

    const musicButtonStyle = { verticalAlign: 'middle', fontSize: '40px' };

    const playTrack = (trackIndex) => {
        audioRef.current.src = tracks[trackIndex];
        audioRef.current.play();
        setIsPlaying(true);
    }

    const prevTrack = () => {
        let index = currentTrackIndex;
        if (index === 0) {
            index = numTracks
        }
        const prevIndex = (index - 1) % numTracks;
        setCurrentTrackIndex(prevIndex)
        playTrack(prevIndex);
    }

    const nextTrack = () => {
        const nextIndex = (currentTrackIndex + 1) % numTracks;
        setCurrentTrackIndex(nextIndex)
        playTrack(nextIndex);
    }

    return (
        <div>
            <img src='/keithharing-sheetmusic.jpg' className='photo' style={{ width: `${width - 40}px` }} />
            <div style={{ textAlign: 'center', maxWidth: '400px' }}>
                <audio src={tracks[0]} ref={audioRef} onEnded={nextTrack} />
                <button className='music-button' onClick={prevTrack}>
                    <FastRewindRounded style={musicButtonStyle} />
                </button>
                <button className='music-button' onClick={() => {
                    setIsPlaying(!isPlaying);
                }}>
                    {isPlaying ?
                        <PauseRounded style={musicButtonStyle} /> :
                        <PlayArrowRounded style={musicButtonStyle} />
                    }

                </button>
                <button className='music-button' onClick={nextTrack}>
                    <FastForwardRounded style={musicButtonStyle} />
                </button>

                <div style={{ fontSize: '15px', color: 'rgb(184, 184, 184)' }}>
                    {currentTrackIndex + 1} of {numTracks}
                </div>
            </div>
        </div>
    );
};

export default Music;